import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Form, UseForm } from "../../components/UseForm";
import React, { useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  billingActionCreator,
  usersActionCreator,
} from "../../redux-state/actions";
import {
  currencies,
  european_country_codes_KM,
  NewCountryCode,
} from "./Countries";
import {
  getTimezoneAndZipcode,
  getTimezoneAndZipcodeForBilling,
} from "../../utils/PlacesAPI";
import ConfirmDialog from "../../components/ConfirmDialog";
import Autocomplete from "@mui/material/Autocomplete";
import BillingPackage from "./BillingPackage";
import CircularProgress from "@mui/material/CircularProgress";
import Controls from "../../components/controls/Controls";
import { DepotType } from "../../constants/Variables";
import Loader from "../../components/Loader";
import Popup from "../../components/Popup";
import ToastMessage from "../../components/ToastMessage";
import apiDepots from "../../services/api/Depots";
import { dateFormats } from "./DateFormats";
import environment from "../../environment";
import format from "date-fns/format";
import { getTimeZones } from "../../services/TimeZoneService";
import { messages } from "../../constants/Messages";
import { titleLabels } from "../../constants/TitleLabels";
import { useHistory } from "react-router-dom";
import {
  validateName,
  validateNonEmptyFields,
} from "../../utils/FieldValidations";
import "./requests.scss";

const useStyles = makeStyles((theme) => ({
  typography: {
    marginTop: theme.spacing(1),
  },
  buttonAlign: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
}));

const initialFieldValues = {
  companyName: "",
  companyId: "",
  depotName: "",
  depotId: "",
  address: "",
  billingAddress: "",
  billingAddressForm: "",
  timeZoneId: "",
  timeZoneOffset: "",
  latitude: "",
  longitude: "",
  city: "",
  country: "",
  state: "",
  postalCode: "",
  countryCode: "",
  streetAddress: "",
  provisionId: "",
  reason: "",
  productChargeId: null,
  productSku: null,
  cloudProvider: "AWS",
  geotabNamespace: "",
};

const ReviewAndConfirmForm = (props) => {
  const dispatch = useDispatch();
  const { saveAllUsersGlobal } = bindActionCreators(
    usersActionCreator,
    dispatch
  );
  const { allUsers, isUsersLoaded } = useSelector(
    (state) => state.allUsersGlobal
  );

  const componentPrivilege = useSelector(
    (state) => state.user.componentPrivilege.requests
  );

  const classes = useStyles();
  const history = useHistory();
  const { recordForEdit } = props;
  const [reasonFieldError, setReasonFieldError] = React.useState("");
  const [additionalInfoLoader, setAdditionalInfoLoader] = React.useState(false);
  const [billingAdditionalInfoLoader, setBillingAdditionalInfoLoader] =
    React.useState(false);
  const [enableCdr, setEnableCdr] = React.useState(false);
  const [isSharedCharging, setIsSharedCharging] = React.useState(false);
  const [isGeotabAddInEnabled, setIsGeotabAddInEnabled] = React.useState(false);
  const [isRFID, setIsRFID] = React.useState(false);
  const [isCircuitEnabled, setIsCircuitEnabled] = React.useState(false);
  const [simulatedDepot, setSimulatedDepot] = React.useState(
    environment[process.env.REACT_APP_NODE_ENV].SIMULATEDDEPOT
  );

  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUsers] = React.useState([]);
  const [isCountryLoaded, setIsCountryLoaded] = React.useState(false);
  const [homeChargingDepot, setHomeChargingDepot] = React.useState(false);
  const [isAdvenirEnrolledDepot, setIsAdvenirEnrolledDepot] =
    React.useState(false);
  const [isWorkplaceChargingDepot, setisWorkplaceChargingDepot] =
    React.useState(false);
  const [sendGetConfiguration, setSendGetConfiguration] = useState(false);

  const [minaEnrolled, setMinaEnrolled] = React.useState(false);
  const [distance, setDistance] = React.useState("");
  const [currency, setCurrency] = React.useState("");
  const [expiryDate, setExpiryDate] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [sourceCountry, setSourceCountry] = React.useState("");
  const [depotType, setDepotType] = React.useState(
    environment[process.env.REACT_APP_NODE_ENV].DEPOT_TYPE
  );
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    type: "primary",
  });
  const [dateFormat, setDateFormat] = React.useState("dd-mmm-yyyy");
  const [distanceError, setDistanceError] = React.useState(false);
  const [expiryDateError, setExpiryDateError] = React.useState(false);
  const [descriptionError, setDescriptionError] = React.useState(false);
  const [currencyError, setCurrencyError] = React.useState(false);
  const [csmUserError, setCsmUserError] = React.useState(false);
  const [dateFormatError, setDateFormatError] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [originalValues, setOriginalValues] = React.useState({});
  const [AllRatePlans, setAllRatePlans] = useState([]);
  const [countryRatePlans, setCountryRatePlans] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [isRatePlanLoaded, SetIsRatePlanLoaded] = useState(false);
  const [toast, setToast] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openPopup, setOpenPopup] = React.useState({
    isOpen: false,
    title: "",
  });
  if (isSharedCharging) {
    initialFieldValues.publicLocationName = "";
  }
  const handleSelectedUser = (event, value) => {
    // let temp = value?.map(single =>{
    //   return single.split('-')[0]
    // })
    setSelectedUsers(value);
  };

  React.useEffect(() => {
    if (isUsersLoaded === false) {
      saveAllUsersGlobal();
    }
    getUserData();
  }, [isUsersLoaded]);

  const getUserData = () => {
    userEmailAndName(allUsers);
  };

  const userEmailAndName = (data) => {
    let allEmails = [];
    data?.map((single) => {
      allEmails.push(`${single.email}-${single.firstname} ${single.lastname}`);
    });
    setUsers(allEmails);
  };

  const CloudProviderOptions = ["AWS", "GCP"];

  const validatePreferences = useCallback(() => {
    setDistanceError(false);
    setCurrencyError(false);
    setCsmUserError(false);
    setDateFormatError(false);
    setExpiryDateError(false);
    setDescriptionError(false);
    let flag = 0;

    if (!currency?.length) {
      setCurrencyError(true);
      flag = 1;
    } else if (distance === "") {
      setDistanceError(true);
      flag = 1;
    } else if (dateFormat === "") {
      setDateFormatError(true);
      flag = 1;
    } else if (depotType === DepotType.TEST && expiryDate === "") {
      setExpiryDateError(true);
      flag = 1;
    } else if (depotType === DepotType.TEST && description === "") {
      setDescriptionError(true);
      flag = 1;
    } else if (selectedUser.length === 0) {
      setCsmUserError(true);
      flag = 1;
    }
    return flag === 0;
  }, [
    selectedUser,
    currency,
    distance,
    dateFormat,
    depotType,
    expiryDate,
    description,
  ]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("provisionId" in fieldValues)
      temp.provisionId = fieldValues.provisionId ? "" : messages.FIELD_REQUIRED;
    if ("companyName" in fieldValues)
      temp.companyName = fieldValues.companyName
        ? validateName(fieldValues.companyName)
          ? ""
          : messages.INVALID_NAME
        : messages.FIELD_REQUIRED;

    if ("cloudProvider" in fieldValues)
      temp.cloudProvider = fieldValues.cloudProvider
        ? validateName(fieldValues.depotName)
          ? ""
          : messages.INVALID_NAME
        : messages.FIELD_REQUIRED;
    if ("companyId" in fieldValues)
      temp.companyId = fieldValues.companyId ? "" : messages.FIELD_REQUIRED;
    if ("depotName" in fieldValues)
      temp.depotName = fieldValues.depotName
        ? validateName(fieldValues.depotName)
          ? ""
          : messages.INVALID_NAME
        : messages.FIELD_REQUIRED;
    if ("depotId" in fieldValues)
      temp.depotId = fieldValues.depotId ? "" : messages.FIELD_REQUIRED;
    if ("depotId" in fieldValues)
      temp.depotId = fieldValues.depotId ? "" : messages.FIELD_REQUIRED;
    if ("productSku" in fieldValues)
      temp.productSku = fieldValues.productSku ? "" : messages.FIELD_REQUIRED;
    if ("zuoraId" in fieldValues)
      temp.zuoraId = fieldValues.zuoraId ? "" : messages.FIELD_REQUIRED;

    if (
      "productChargeId" in fieldValues &&
      values.productSku !== "Non Billable"
    )
      temp.productChargeId = fieldValues.productChargeId
        ? ""
        : messages.FIELD_REQUIRED;
    if (isSharedCharging && "publicLocationName" in fieldValues) {
      temp.publicLocationName = fieldValues.publicLocationName
        ? ""
        : messages.FIELD_REQUIRED;
    }
    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : messages.FIELD_REQUIRED;
    if (!values.billingAddress) {
      if ("billingAddressForm" in fieldValues)
        temp.billingAddressForm = fieldValues.billingAddressForm
          ? ""
          : messages.FIELD_REQUIRED;
    }
    if (isGeotabAddInEnabled && "geotabNamespace" in fieldValues) {
      temp.geotabNamespace = fieldValues.geotabNamespace
        ? ""
        : messages.FIELD_REQUIRED;
    }

    // Check additional fields only when address is given
    if (fieldValues.address) {
      if ("timeZoneId" in fieldValues)
        temp.timeZoneId = fieldValues.timeZoneId ? "" : messages.FIELD_REQUIRED;
      if ("timeZoneOffset" in fieldValues)
        temp.timeZoneOffset = fieldValues.timeZoneOffset
          ? ""
          : messages.FIELD_REQUIRED;
      if ("latitude" in fieldValues)
        temp.latitude = fieldValues.latitude ? "" : messages.FIELD_REQUIRED;
      if ("longitude" in fieldValues)
        temp.longitude = fieldValues.longitude ? "" : messages.FIELD_REQUIRED;
      if ("city" in fieldValues)
        temp.city = fieldValues.city ? "" : messages.FIELD_REQUIRED;
      if ("country" in fieldValues)
        temp.country = fieldValues.country ? "" : messages.FIELD_REQUIRED;
      if ("state" in fieldValues)
        temp.state = fieldValues.state ? "" : messages.FIELD_REQUIRED;
      if ("postalCode" in fieldValues)
        temp.postalCode = fieldValues.postalCode ? "" : messages.FIELD_REQUIRED;
      if ("streetAddress" in fieldValues)
        temp.streetAddress = fieldValues.streetAddress
          ? ""
          : messages.FIELD_REQUIRED;
    }
    if (values.productSku === "Non Billable") temp.productChargeId = "";
    setErrors({ ...temp });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
    else return false;
  };

  const validateReason = () => {
    if (!values.reason) {
      setReasonFieldError(messages.FIELD_REQUIRED);
      return false;
    } else {
      setReasonFieldError("");
      return true;
    }
  };

  const onCountryChange = (event, values) => {
    setCurrency(values?.value);
  };
  const handleDistance = (event) => {
    setDistance(event.target.value);
  };
  const handleDateFormat = (event) => {
    setDateFormat(event.target.value);
  };
  const handleExpiryDate = (event) => {
    setExpiryDate(event.target.value);
  };
  const handleDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleChangeInCloudProvider = (event) => {
    setValues({ ...values, cloudProvider: event.target.value });
  };
  const handleDepotType = (event) => {
    if (event.target.value !== DepotType.TEST) {
      setExpiryDate(null);
      setDescription(null);
    }
    setDepotType(event.target.value);
  };

  const handleHomeChargingChange = (isHomeChargingDepotToggle) => {
    if (isHomeChargingDepotToggle) {
      setIsCircuitEnabled(false);
    } else setMinaEnrolled(false);
    setHomeChargingDepot(isHomeChargingDepotToggle);
  };

  const handleIsAdvenirEnrolledDepotChange = (isAdvenirEnrolledToggle) => {
    if (isAdvenirEnrolledToggle) {
      setIsAdvenirEnrolledDepot(true);
    } else setIsAdvenirEnrolledDepot(false);
  };
  const handleIsGeotabEnabledDepotChange = (isGeotabAddInEnabledToggle) => {
    if (isGeotabAddInEnabledToggle) {
      setIsGeotabAddInEnabled(true);
    } else setIsGeotabAddInEnabled(false);
  };

  const handleisWorkplaceChargingDepotChange = (
    isWorkplaceChargingDepotToggle
  ) => {
    if (isWorkplaceChargingDepotToggle) {
      setisWorkplaceChargingDepot(true);
    } else setisWorkplaceChargingDepot(false);
  };
  const handleSendConfigurationChange = (
    isSendGetConfigurationEnabledToggle
  ) => {
    if (isSendGetConfigurationEnabledToggle) {
      setSendGetConfiguration(true);
    } else setSendGetConfiguration(false);
  };

  const { values, setValues, errors, setErrors, handleFormInputChange } =
    UseForm(initialFieldValues, true, validate);

  const validateBillingDetails = useCallback(() => {
    const fields = [];
    if (values.isZuoraEditable) fields.push("zuoraId");
    fields.push("productSku");
    if (values.productSku !== "Non Billable") fields.push("productChargeId");
    const validData = validateNonEmptyFields(fields, values, setErrors);
    if (validData) setErrors({});
    return validData;
  }, [values]);

  const validateLocation = useCallback(() => {
    const fields = [
      "address",
      "timeZoneId",
      "timeZoneOffset",
      "latitude",
      "longitude",
      "city",
      "country",
      "state",
      "countryCode",
      "postalCode",
      "streetAddress",
    ];
    if (isSharedCharging) fields.push("publicLocationName");
    if (isGeotabAddInEnabled) fields.push("geotabNamespace");
    const validData = validateNonEmptyFields(fields, values, setErrors);
    if (validData) setErrors({});
    return validData;
  }, [values]);

  const handleNext = (index) => {
    let isValid = false;
    if (index === 0) isValid = validateBillingDetails();
    else if (index === 1) isValid = validatePreferences();
    else if (index === 2) isValid = validateLocation();
    if (isValid) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleReset = () => setActiveStep(0);

  const stepperLabel = [
    titleLabels.BILLING_PACKAGE,
    titleLabels.PREFERENCES_TITLE,
    titleLabels.LOCATION,
  ];

  const stepperContent = useMemo(() => {
    let steps = [];
    steps.push(
      <Grid container>
        {!originalValues.billingAddress ? (
          <>
            <Grid item md={12}>
              <Controls.AddressInput
                id="google-map-suggestion"
                label="Billing Address"
                name="billingAddressForm"
                value={values.billingAddressForm || ""}
                error={errors.billingAddressForm}
                onChange={handleFormInputChange()}
              />
              {isCountryLoaded === true && <CircularProgress size={20} />}
            </Grid>
          </>
        ) : (
          <Grid item md={12}>
            <Controls.Input
              label="Billing Address"
              value={values.billingAddress.streetAddress}
              editable={false}
            />
          </Grid>
        )}
        {values.billingAddress && (
          <BillingPackage
            values={values}
            handleFormInputChange={handleFormInputChange}
            setValues={setValues}
            errors={errors}
            isZuoraVisible={true}
            AllRatePlans={countryRatePlans}
          />
        )}
      </Grid>
    );
    steps.push(
      <Grid container>
        <Grid item xs={6}>
          <FormControl variant="outlined" error={currencyError}>
            <Autocomplete
              id="combo-box-demo"
              options={currencies}
              value={
                currency?.length
                  ? currencies.find((row) => row.value === currency)
                  : ""
              }
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              size="small"
              className="currency_autocomplete"
              onChange={onCountryChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Currency"
                  variant="outlined"
                  error={currencyError}
                />
              )}
            />

            {currencyError && (
              <FormHelperText>This field is required</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" error={distanceError}>
            <InputLabel size="small" id="demo-simple-select-filled-label">
              Distance
            </InputLabel>
            <MuiSelect
              size="small"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={distance}
              onChange={handleDistance}
              label="Distance"
              variant="outlined"
            >
              <MenuItem value="KM">Kilometres</MenuItem>
              <MenuItem value="MILES">Miles</MenuItem>
            </MuiSelect>
            {distanceError && (
              <FormHelperText>This field is required</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" error={dateFormatError}>
            <InputLabel size="small" id="demo-simple-select-filled-label">
              Date Format
            </InputLabel>
            <MuiSelect
              size="small"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={dateFormat}
              onChange={handleDateFormat}
              label="Date Format"
              variant="outlined"
            >
              {dateFormats.map((single) => {
                return (
                  <MenuItem key={single.dateFormat} value={single.dateFormat}>
                    {single.dateFormat}
                  </MenuItem>
                );
              })}
            </MuiSelect>
            {dateFormatError && (
              <FormHelperText>This field is required</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined">
            <InputLabel size="small" id="demo-simple-select-filled-label">
              Depot Type
            </InputLabel>
            <MuiSelect
              size="small"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={depotType}
              onChange={handleDepotType}
              label="Depot Type"
              variant="outlined"
            >
              <MenuItem value={DepotType.TEST}>
                {titleCase(DepotType.TEST)}
              </MenuItem>
              <MenuItem value={DepotType.INTERNAL}>
                {titleCase(DepotType.INTERNAL)}
              </MenuItem>
              <MenuItem value={DepotType.PILOT}>
                {titleCase(DepotType.PILOT)}
              </MenuItem>
              <MenuItem value={DepotType.CUSTOMER}>
                {titleCase(DepotType.CUSTOMER)}
              </MenuItem>
              <MenuItem value={DepotType.DEALER}>
                {titleCase(DepotType.DEALER)}
              </MenuItem>
            </MuiSelect>
          </FormControl>
        </Grid>
        {depotType === DepotType.TEST && (
          <>
            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                id="completeWidth"
                error={expiryDateError}
              >
                <TextField
                  size="small"
                  label="Expiry Date"
                  type="date"
                  error={expiryDateError}
                  value={expiryDate}
                  variant="outlined"
                  onChange={handleExpiryDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ min: format(new Date(), "yyyy-MM-dd") }}
                />
                {expiryDateError && (
                  <FormHelperText>This field is required</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl id="completeWidth" error={descriptionError}>
                <TextField
                  size="small"
                  label="Description"
                  name="description"
                  error={descriptionError}
                  variant="outlined"
                  {...(descriptionError
                    ? { error: true, helperText: "This field is required" }
                    : "")}
                  value={description}
                  onChange={handleDescription}
                />
              </FormControl>
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <FormControl variant="outlined" error={csmUserError}>
            <Autocomplete
              multiple
              id="combo-box-demo"
              size="small"
              name="CMSUsers"
              disableCloseOnSelect
              value={selectedUser}
              className="currency_autocomplete"
              onChange={handleSelectedUser}
              options={users}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="CSM Users"
                  error={csmUserError}
                />
              )}
            />
            {csmUserError && (
              <FormHelperText>This field is required</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Controls.Select
            name="Cloud Provider"
            label="Cloud Provider"
            options={CloudProviderOptions}
            value={values.cloudProvider}
            onChange={handleChangeInCloudProvider}
            error={errors.cloudProvider}
          />
        </Grid>
      </Grid>
    );
    steps.push(
      <Grid container>
        <Grid item md={12}>
          {isGeotabAddInEnabled ? (
            <Controls.Input
              label="Geotab AddIn Namespace"
              name="geotabNamespace"
              value={values.geotabNamespace}
              onChange={handleFormInputChange()}
              error={errors.geotabNamespace}
            />
          ) : null}
        </Grid>
        <Grid item md={12}>
          {isSharedCharging ? (
            <Controls.Input
              label="Public Location Name"
              name="publicLocationName"
              value={values.publicLocationName}
              onChange={handleFormInputChange()}
              error={errors.publicLocationName}
            />
          ) : null}
        </Grid>
        <Grid item md={12}>
          {values.status === "provisioning" ? (
            <Controls.Input
              label="Address"
              value={values.address}
              editable={false}
            />
          ) : (
            <Controls.AddressInput
              id="google-map-suggestion"
              label="Address"
              name="address"
              value={values.address || ""}
              error={errors.address}
              onChange={handleFormInputChange()}
            />
          )}
        </Grid>
        {values.address && additionalInfoLoader ? (
          <Grid container direction="row">
            <CircularProgress size={20} className="leftMargin" />
            <Typography
              variant="subtitle2"
              align="center"
              className="leftMargin"
            >
              Fetching Additional Info . . .
            </Typography>
          </Grid>
        ) : values.address ? (
          <>
            <Grid item xs={6}>
              <Controls.AutoSuggestion
                id="timeZoneId"
                label="Time Zone"
                name="timeZoneId"
                value={values.timeZoneId}
                onChange={handleFormInputChange()}
                options={getTimeZones()}
                error={errors.timeZoneId}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="timeZoneOffset"
                label="Time Zone Offset"
                value={values.timeZoneOffset}
                onChange={handleFormInputChange()}
                error={errors.timeZoneOffset}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="latitude"
                label="Latitude"
                value={values.latitude}
                onChange={handleFormInputChange()}
                error={errors.latitude}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="longitude"
                label="Longitude"
                value={values.longitude}
                onChange={handleFormInputChange()}
                error={errors.longitude}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="city"
                label="City"
                value={values.city}
                onChange={handleFormInputChange()}
                error={errors.city}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="country"
                label="Country"
                value={values.country}
                onChange={handleFormInputChange()}
                error={errors.country}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="state"
                label="State"
                value={values.state}
                onChange={handleFormInputChange()}
                error={errors.state}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="countryCode"
                label="Country Code"
                value={values.countryCode}
                onChange={handleFormInputChange()}
                error={errors.countryCode}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="postalCode"
                label="Postal Code"
                value={values.postalCode || ""}
                onChange={handleFormInputChange()}
                error={errors.postalCode}
              />
            </Grid>
            <Grid item md={6}>
              <Controls.Input
                id="streetAddress"
                label="Street Address"
                name="streetAddress"
                value={values.streetAddress}
                error={errors.streetAddress}
                onChange={handleFormInputChange()}
              />
            </Grid>
          </>
        ) : null}
        <Grid container>
          <Grid item xs={12} marginBottom={0}>
            <div className="single_details" id="configCheckboxGroup">
              <p className="title">Config Flags</p>
              <p className="title_value">Cannot be changed later</p>
            </div>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={simulatedDepot}
                  onChange={() => setSimulatedDepot(!simulatedDepot)}
                  name="simulatedDepot"
                  color="primary"
                  className="checkBox"
                  disabled={process.env.REACT_APP_NODE_ENV === "PROD"}
                />
              }
              label="Simulated Depot"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={homeChargingDepot}
                  onChange={() => handleHomeChargingChange(!homeChargingDepot)}
                  name="homeChargingDepot"
                  color="primary"
                  className="checkBox"
                />
              }
              label="Home Charging Depot"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={enableCdr}
                  onChange={() => setEnableCdr(!enableCdr)}
                  name="enableCdr"
                  color="primary"
                  className="checkBox"
                />
              }
              label="Enable CDR"
            />
          </Grid>
          <Grid item />
          <Grid item xs={12}>
            <div className="single_details" id="configCheckboxGroup">
              <p className="title_value">Can be changed later</p>
            </div>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={
                    !componentPrivilege?.includes("Shared Charging Toggle") ||
                    isCircuitEnabled
                  }
                  checked={isSharedCharging}
                  onChange={() => setIsSharedCharging(!isSharedCharging)}
                  name="isSharedCharging"
                  color="primary"
                  className="checkBox"
                />
              }
              label="Shared Charging"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRFID}
                  onChange={() => setIsRFID(!isRFID)}
                  name="isRFID"
                  color="primary"
                  className="checkBox"
                />
              }
              label="Enable RFID"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={homeChargingDepot || isSharedCharging}
                  checked={isCircuitEnabled}
                  onChange={() => setIsCircuitEnabled(!isCircuitEnabled)}
                  name="isCircuitEnabled"
                  color="primary"
                  className="checkBox"
                />
              }
              label="Enable Circuits"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!homeChargingDepot}
                  checked={minaEnrolled}
                  onChange={() => setMinaEnrolled(!isCircuitEnabled)}
                  name="minaEnrolled"
                  color="primary"
                  className="checkBox"
                />
              }
              label="Mina Enrolled"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAdvenirEnrolledDepot}
                  onChange={() =>
                    handleIsAdvenirEnrolledDepotChange(!isAdvenirEnrolledDepot)
                  }
                  name="isAdvenirEnrolledDepot"
                  color="primary"
                  className="checkBox"
                />
              }
              label="Advenir Enrolled"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isGeotabAddInEnabled}
                  onChange={() =>
                    handleIsGeotabEnabledDepotChange(!isGeotabAddInEnabled)
                  }
                  name="isGeotabAddInEnabled"
                  color="primary"
                  className="checkBox"
                />
              }
              label="Geotab AddIn Enabled"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isWorkplaceChargingDepot}
                  onChange={() =>
                    handleisWorkplaceChargingDepotChange(
                      !isWorkplaceChargingDepot
                    )
                  }
                  name="isWorkplaceChargingDepot"
                  color="primary"
                  className="checkBox"
                />
              }
              label="Workplace Charging"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendGetConfiguration}
                  onChange={() =>
                    handleSendConfigurationChange(!sendGetConfiguration)
                  }
                  name="sendGetConfiguration"
                  color="primary"
                  className="checkBox"
                />
              }
              label="Send GetConfiguration"
            />
          </Grid>
        </Grid>
      </Grid>
    );
    return steps;
  }, [
    values,
    errors,
    originalValues,
    isCountryLoaded,
    countryRatePlans,
    currency,
    distance,
    dateFormat,
    depotType,
    expiryDate,
    description,
    selectedUser,
    users,
    currencyError,
    distanceError,
    dateFormatError,
    expiryDateError,
    descriptionError,
    csmUserError,
    additionalInfoLoader,
    simulatedDepot,
    homeChargingDepot,
    enableCdr,
    isSharedCharging,
    isRFID,
    isCircuitEnabled,
    minaEnrolled,
    isAdvenirEnrolledDepot,
    isGeotabAddInEnabled,
    isWorkplaceChargingDepot,
    sendGetConfiguration,
  ]);

  const handleDeclineConfirm = () => {
    if (validateReason()) {
      setLoading(true);
      apiDepots
        .confirmDecline(values.companyId, values.depotId, {
          depotId: values.depotId,
          status: "declined",
          message: values.reason,
        })
        .then((res) => {
          setLoading(false);
          if (res.code === 200) {
            setToast({
              isOpen: true,
              message: "Declined",
              type: "success",
            });
            setOpenPopup({
              isOpen: false,
            });
            history.push("/requests");
          } else {
            setToast({
              isOpen: true,
              message: "Internal Service Failure ",
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log("error declining", error);
          setToast({
            isOpen: true,
            message: "Internal Service Failure ",
            type: "error",
          });
        });
    }
  };

  React.useEffect(() => {
    if (values.address && values.address.place_id) {
      getTimezoneAndZipcode(
        values.address.place_id,
        setValues,
        setAdditionalInfoLoader
      );
    }
  }, [values.address]);

  React.useEffect(() => {
    if (values.billingAddressForm && values.billingAddressForm.place_id) {
      setValues((prevState) => ({
        ...prevState,
        productChargeId: null,
        productSku: null,
      }));
      getTimezoneAndZipcodeForBilling(
        values.billingAddressForm.place_id,
        setValues,
        setBillingAdditionalInfoLoader,
        setIsCountryLoaded
      );
    } else {
      setValues((prevState) => ({
        ...prevState,
        productChargeId: null,
        productSku: null,
        billingAddress: null,
      }));
    }
  }, [values.billingAddressForm]);

  const handleApproveChange = (e) => {
    if (validate() && validatePreferences()) {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: true,
        title: "Approve",
        subTitle:
          "Approving the depot will trigger the provisioning of depot infrastructure in FPC Infra, resulting in substantial charges. Therefore, it is advisable to provision depots only when there are chargers ready to point. Otherwise, it is best to postpone the provisioning until chargers are ready.",
        subTitleOne: "Are you sure you want to Approve?",
        type: "secondary",
        critical: true,
        onConfirm: () => {
          handleSubmit(e);
        },
      });
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    const payload = {
      provisionId: values.provisionId,
      companyId: values.companyId,
      companyName: values.companyName,
      depotId: values.depotId,
      depotName: values.depotName,
      cdr: enableCdr,
      isSharedChargingDepot: isSharedCharging,
      isRFIDDepot: isRFID,
      isCircuitEnabled: isCircuitEnabled,
      isHomeChargingDepot: homeChargingDepot,
      isMinaEnrolledDepot: minaEnrolled,
      isAdvenirEnrolledDepot: isAdvenirEnrolledDepot,
      isWorkplaceChargingDepot: isWorkplaceChargingDepot,
      sendGetConfiguration: sendGetConfiguration,
      isSimulated: simulatedDepot,
      productRatePlanChargeName:
        values.productSku !== "Non Billable" ? values.productChargeId : null,
      productSku: values.productSku,
      zuoraId: values.zuoraId,
      depotType: depotType,
      billingAddress: values.billingAddress,
      depotCSM: selectedUser?.map((single) => {
        return single.split("-")[0];
      }),
      geotabDetails: {
        enabled: isGeotabAddInEnabled,
        namespace: values.geotabNamespace,
      },
      preferences: {
        distanceUnit: distance,
        currency: currency,
        dateFormat: dateFormat,
      },
      location: {
        address: values.address.description,
        timeZoneId: values.timeZoneId,
        timeZoneOffset: values.timeZoneOffset,
        latitude: values.latitude,
        longitude: values.longitude,
        city: values.city,
        country: values.country,
        countryCode: values.countryCode,
        state: values.state,
        postalCode: values.postalCode,
        streetAddress: values.streetAddress,
        publicLocationName: values.publicLocationName,
      },
      expiryDate: expiryDate,
      description: description,
      cloudProvider: values.cloudProvider,
    };
    if (isSharedCharging) {
      payload.location.publicLocationName = values.publicLocationName;
    }
    apiDepots.provision(payload).then((res) => {
      setLoading(false);
      if (res.statusCode >= 200 && res.statusCode <= 299) {
        setToast({
          isOpen: true,
          message: "Success",
          type: "success",
        });
        history.push("/requests");
      } else if (res.statusCode === 400) {
        setToast({
          isOpen: true,
          message: res?.data?.data,
          type: "error",
        });
      } else {
        setToast({
          isOpen: true,
          message: "Internal Service Failure",
          type: "error",
        });
      }
    });
    setConfirmDialog({ ...confirmDialog, isOpen: false });
  };
  const { saveAllBillingRatePlansGlobal } = bindActionCreators(
    billingActionCreator,
    dispatch
  );
  const { allBillingRatePlansResponse, isBillingRatePlansLoaded } = useSelector(
    (state) => state.getBillingRatePlans_Global
  );
  const getRatePlansResponse = () => {
    if (isBillingRatePlansLoaded === false) {
      return saveAllBillingRatePlansGlobal();
    }
    return allBillingRatePlansResponse;
  };
  React.useEffect(() => {
    if (recordForEdit) {
      setOriginalValues((prevState) => ({
        ...prevState,
        billingAddress: recordForEdit.billingAddress,
      }));
      setValues((prevState) => ({
        ...prevState,
        companyName: recordForEdit.companyName,
        companyId: recordForEdit.companyId,
        depotName: recordForEdit.depotName,
        depotId: recordForEdit.depotId,
        provisionId: recordForEdit.provisionId,
        zuoraId: recordForEdit.zuoraId,
        billingAddress: recordForEdit.billingAddress,
        isZuoraEditable: recordForEdit.zuoraId ? false : true,
      }));
    }
  }, [recordForEdit]);

  React.useEffect(() => {
    if ("billingAddress" in values && values.billingAddress) {
      if ("countryCode" in values.billingAddress) {
        if (values?.billingAddress?.countryCode) {
          let countryCodeToSearch = NewCountryCode.find(
            (x) => x.name === values.billingAddress.country
          ).countryCode;
          const euroCountrys = [
            "POL",
            "AUT",
            "IRL",
            "ESP",
            "LUX",
            "NLD",
            "NOR",
            "DNK",
            "BEL",
            "DEU",
            "FRA",
            "ITA",
          ];
          if (euroCountrys.includes(countryCodeToSearch)) {
            countryCodeToSearch = "GBR";
          }
          if (countryCodeToSearch === undefined) {
            countryCodeToSearch = "USA";
          }
          setCountryRatePlans(
            AllRatePlans.filter((i) => i.countryCode == countryCodeToSearch)
          );
        }
      }
    }
    if ("billingAddressForm" in values)
      if (values.billingAddressForm === null) {
        setValues((prevState) => ({
          ...prevState,
          productChargeId: null,
          productSku: null,
        }));
      }
  }, [values.billingAddress, AllRatePlans, values.billingAddressForm]);

  React.useEffect(() => {
    fetchAllRatePlans();
  }, []);
  React.useEffect(() => {
    if (
      values?.billingAddress?.countryCode == "USA" ||
      values?.billingAddress?.countryCode == "MEX"
    ) {
      setCurrency("USD");

      if (values?.billingAddress?.countryCode == "MEX") {
        setDistance("KM");
      } else {
        setDistance("MILES");
      }
    }
    if (values?.billingAddress?.countryCode == "GBR") {
      setCurrency("GBP");
      setDistance("MILES");
    }
    if (values?.billingAddress?.countryCode == "JPN") {
      setCurrency("JPY");
      setDistance("MILES");
    }
    if (values?.billingAddress?.countryCode == "CAN") {
      setCurrency("CAD");
      setDistance("KM");
    }
    if (
      european_country_codes_KM.includes(values?.billingAddress?.countryCode)
    ) {
      setCurrency("EUR");
      setDistance("KM");
    }
  }, [values.billingAddress]);

  const fetchAllRatePlans = async () => {
    SetIsRatePlanLoaded(false);
    const res = await getRatePlansResponse();
    if (res.code === 200) {
      setAllRatePlans(res.data);
      // setAllRatePlans(res.data.filter((i) => i.countryCode == countryCode));
      SetIsRatePlanLoaded(true);
    } else {
      SetIsRatePlanLoaded(false);
      setToast({
        isOpen: true,
        message: "Failed to fetch Rate plans",
        type: "error",
      });
    }
  };
  function titleCase(string) {
    return string.charAt(0) + string.slice(1).toLowerCase();
  }

  return (
    <div className="ReviewAndConfirmForm">
      {<Loader isLoading={isLoading} />}
      <Form>
        <div>
          <Grid container>
            <Grid item xs={6}>
              <div className="single_details">
                <p className="title">Company Name</p>
                <p className="title_value">{values.companyName}</p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="single_details">
                <p className="title">Company ID</p>
                <p className="title_value">{values.companyId}</p>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <div className="single_details">
                <p className="title">Depot Name</p>
                <p className="title_value">{values.depotName}</p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="single_details">
                <p className="title">Depot ID</p>
                <p className="title_value">{values.depotId}</p>
              </div>
            </Grid>
          </Grid>
          <Stepper activeStep={activeStep} orientation="vertical">
            {stepperLabel.map((label, index) => (
              <Step key={"step-" + index}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  {stepperContent?.[index]}
                  <div>
                    <div>
                      <Button
                        disabled={
                          activeStep === 0 ||
                          (activeStep === stepperLabel.length - 1 &&
                            additionalInfoLoader)
                        }
                        onClick={() => handleBack()}
                        className={classes.buttonAlign}
                      >
                        Back
                      </Button>
                      <Button
                        disabled={
                          activeStep === stepperLabel.length - 1 &&
                          additionalInfoLoader
                        }
                        variant="contained"
                        color="primary"
                        onClick={() => handleNext(index)}
                        className={classes.buttonAlign}
                      >
                        {activeStep === stepperLabel.length - 1
                          ? "Finish"
                          : "Next"}
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {values.status !== "provisioning" ? (
            <Grid container justifyContent="space-between">
              <Grid item xs={6}>
                {activeStep === stepperLabel.length && (
                  <div className={classes.buttonAlign}>
                    <Controls.Button
                      color="primary"
                      text="Edit"
                      onClick={handleReset}
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={6}>
                <div className="actionButtonGroup">
                  <Controls.Button
                    color="primary"
                    text="Decline"
                    onClick={() => {
                      setOpenPopup({
                        isOpen: true,
                        title: "Decline",
                      });
                    }}
                  />
                  {activeStep === stepperLabel.length && (
                    <Controls.Button
                      text={"Approve"}
                      onClick={() => {
                        handleApproveChange();
                      }}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          ) : null}
        </div>
      </Form>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} md={12}>
            <Controls.TextArea
              name="reason"
              label="Reason"
              value={values.reason}
              error={reasonFieldError}
              className="fullWidth"
              onChange={handleFormInputChange()}
            />
          </Grid>
          <Grid>
            <div className={classes.buttonAlign}>
              <Controls.Button
                color="primary"
                text={"Cancel"}
                onClick={() => {
                  setOpenPopup({
                    isOpen: false,
                  });
                }}
              />
            </div>
          </Grid>
          <Grid>
            <div className={classes.buttonAlign}>
              <Controls.Button
                text={"Confirm"}
                onClick={() => handleDeclineConfirm()}
              />
            </div>
          </Grid>
        </Grid>
      </Popup>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <ToastMessage toast={toast} setToast={setToast} />
    </div>
  );
};

export default ReviewAndConfirmForm;
